
::-webkit-scrollbar {
    width: 8px; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: white; 
    border: 2px solid #49559d; 
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }